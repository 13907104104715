html,
body {
    padding: 0px !important;
    margin: 0px !important;
    width: 100% !important;
    box-sizing: border-box !important;
}

::-webkit-scrollbar {
    width: 8px;
}

::-webkit-scrollbar-thumb{
    border-radius: 10px;
}

html[data-theme='light'] ::-webkit-scrollbar-track {
    background-color: #ddd9d9;
}

html[data-theme='light'] ::-webkit-scrollbar-thumb {
    background-color: #aaa8a8;
}

html[data-theme='light'] ::-webkit-scrollbar-thumb:hover {
    background-color: #7a7979;
}

html[data-theme='dark'] ::-webkit-scrollbar-track {
    background-color: #333333;
}

html[data-theme='dark'] ::-webkit-scrollbar-thumb {
    background-color: #565656;
}

html[data-theme='dark'] ::-webkit-scrollbar-thumb:hover {
    background-color: #747373;
}

body .uwy .uai{
    width: 35px !important;
    height: 35px !important;
}

.userway_buttons_wrapper {
    top: 35px !important;
    left: calc(100vw - 2vw) !important;
}

@media screen and (max-width: 768px) {
    .uwy{
        z-index: 1150 !important;
    }
    
    .userway_buttons_wrapper {
        top: 30px !important;
        left: 50% !important;
        transform: translate(-50%) !important;
    }
    
    
}