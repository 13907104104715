@keyframes rotate {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.general-backdrop {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, .5);
    z-index: 100;
}

.backdrop-circle {
    width: 100px;
    height: 100px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.circle-border {
    width: 100px;
    height: 100px;
    border: 8px solid transparent;
    background: linear-gradient(#010A11, #010A11) padding-box,
                linear-gradient(to bottom, #c9f81d, #354203) border-box;
    border-radius: 50%;
    animation: rotate 1s linear infinite;
    /* color: #810517; */
}

.backdrop-letter {
    position: absolute;
    top: 49%;
    left: 55%;
    transform: translate(-50%, -50%);
    font-size: 70pt;
    font-family: Arial, Helvetica, sans-serif;
    color: #809C14;
    user-select: none;
}

@media screen and (min-width:791px) {
    .backdrop-letter{
        top: 52%;
    }
}